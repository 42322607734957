<script>
import * as echarts from "echarts";

import {getMap} from "@/api/bigData";
import {monitorData} from "@/api/common";

export default {
  render: function (createElement) {
    return createElement("div", {
      attrs: {
        id: "china",
      },
      style: {
        width: "824px",
        height: "932px",
        margin: "auto",
      },
    });
  },
  data() {
    return {
      dataList: [
        {ename:'',name: "南海诸岛", code: '', value: 0},
        {ename: "beijing", name: "北京市", code: '110000', value: 0},
        {ename: "tianjin", name: "天津市", code: '120000', value: 0},
        {ename: "shanghai", name: "上海市", code: '310000', value: 0},
        {ename: "chongqing", name: "重庆市", code: '500000', value: 0},
        {ename: "hebei", name: "河北省", code: '130000', value: 0},
        {ename: "henan", name: "河南省", code: '410000', value: 0},
        {ename: "yunnan", name: "云南省", code: '530000', value: 0},
        {ename: "liaoning", name: "辽宁省", code: '210000', value: 0},
        {ename: "heilongjiang", name: "黑龙江省", code: '230000', value: 0},
        {ename: "hunan", name: "湖南省", code: '430000', value: 0},
        {ename: "anhui", name: "安徽省", code: '340000', value: 0},
        {ename: "shandong", name: "山东省", code: '370000', value: 0},
        {ename: "xinjiang", name: "新疆维吾尔自治区", code: '650000', value: 0},
        {ename: "jiangsu", name: "江苏省", code: '320000', value: 0},
        {ename: "zhejiang", name: "浙江省", code: '330000', value: 0},
        {ename: "jiangxi", name: "江西省", code: '360000', value: 0},
        {ename: "hubei", name: "湖北省", code: '420000', value: 0},
        {ename: "guangxi", name: "广西壮族自治区", code: '450000', value: 0},
        {ename: "gansu", name: "甘肃省", code: '620000', value: 0},
        {ename: "shanxi", name: "山西省", code: '140000', value: 0},
        {ename: "neimenggu", name: "内蒙古自治区", code: '150000', value: 0},
        {ename: "shanxi1", name: "陕西省", code: '610000', value: 0},
        {ename: "jilin", name: "吉林省", code: '220000', value: 0},
        {ename: "fujian", name: "福建省", code: '350000', value: 0},
        {ename: "guizhou", name: "贵州省", code: '520000', value: 0},
        {ename: "guangdong", name: "广东省", code: '440000', value: 0},
        {ename: "qinghai", name: "青海省", code: '630000', value: 0},
        {ename: "xizang", name: "西藏自治区", code: '540000', value: 0},
        {ename: "sichuan", name: "四川省", code: '510000', value: 0},
        {ename: "ningxia", name: "宁夏回族自治区", code: '640000', value: 0},
        {ename: "hainan", name: "海南省", code: '460000', value: 0},
        {ename: "xianggang", name: "香港特别行政区", code: '', value: 0},
        {ename: "aomen", name: "澳门特别行政区", code: '', value: 0},
        {ename: "taiwan", name: "台湾省", code: '', value: 0},
      ],
      phase_code: '',
    };
  },
  created() {
    this.phase_code = this.$route.params.phase_code || ''
  },
  methods: {
    async initEcharts() {
      const _this = this;
      const mapJson = await getMap(
        `https://geo.datav.aliyun.com/areas_v3/bound/100000_full.json`
      );

      const res = await monitorData()
      const { province } = res
      for (let item of province) {
        const target = this.dataList.find(iter => iter.name === item.name)
        if (target) {
          target.value = item.size
        }
      }

      let myChart = echarts.init(document.getElementById("china"));
      echarts.registerMap("china", mapJson);

      let option = {
        tooltip: {
          trigger: 'item',
          formatter: '{b}<br/>电站数量:{c}'
        },
        visualMap: {
          type: 'piecewise',
          left: 40,
          bottom: 40,
          dimension: 'value',
          // text: ["高", "低"], //取值范围的文字
          pieces: [
            { min: 0, max: 0, label: '0',},
            { min: 1, max: 100, label: '1-100',},
            { min: 101, max: 1000, label: '101-1000',},
            { min: 1001, max: 10000, label: '1001-1000',},
            { gt: 10000, label: '>10000',},
          ],
          inRange: {
            color: [
              '#107495',
              '#1488AF',
              '#179DCA',
              '#1BB1E4',
              '#1FC6FF',
            ],
          },
          textStyle: {
            color: '#FFFFFF'
          },
          itemWidth: 64,
          itemHeight: 24,
          itemSymbol: 'rect',
        },
        geo: {
          map: "china", //引入地图数据
          zoom: 1, //视角缩放比例
          roam: true, //是否开启平游或缩放
          scaleLimit: {
            //滚轮缩放的极限控制
            min: 1,
            max: 2, //可以放大几倍
          },
          label: {
            normal: {
              show: true,
              fontSize: "10",
              color: "#fff",
            },
            // 鼠标悬浮
            emphasis: {
              show: true,
              color: '#fff'
            },
          },
          itemStyle: {
            normal: {
              borderColor: "rgba(0, 0, 0, 0.2)",
              areaColor: "#e0f3f8",
            },
            emphasis: {
              //高亮的显示设置
              areaColor: "skyblue", //鼠标选择区域颜色
              shadowOffsetX: 0,
              shadowOffsetY: 0,
              shadowBlur: 20,
              borderWidth: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
        // 鼠标悬浮提示框
        series: [
          {
            name: "省份",
            type: "map",
            geoIndex: 0,
            label: {
              show: true
            },
            data: this.dataList,
          },
        ],
      };
      myChart.setOption(option);
      myChart.on("click", function (params) {
        // console.log(params, '-------params-');
        if (!params.data.code) {
          _this.$message.info("暂无" + params.name + "地图数据")
          return;
        }
        _this.$router.push({
          path: "/admin/monitorBoard/province/" + (_this.phase_code ?? 0),
          query: {provinceName: params.data.ename, province: params.data.name, code: params.data.code},
        });
      });
    },
  },
  mounted() {
    this.initEcharts()
  },
};
</script>
<style scoped>
.back {
  color: rgba(0, 0, 0, 0.5);
}
</style>
